import axios from 'axios';
import CONFIG from './config';

// Instantiate axios
let _axios = null;
let _axios2 = null;

export function initializeAxios(isServer) {
  if (isServer || _axios === null) {
    _axios = axios.create({
      baseURL: CONFIG.backend.base
    });
  }
  return _axios;
}

export function initializeAxios2(isServer) {
  if (isServer || _axios2 === null) {
    _axios2 = axios.create({
      baseURL: CONFIG.backend.base2
    });
  }
  return _axios2;
}

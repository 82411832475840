import { Toast } from 'antd-mobile';
import axiosRetry from 'axios-retry';
import jsonp from 'jsonp';
import Cookies from 'universal-cookie';
import mitt, { EVENT } from './common/SMEvent';
import { initializeAxios, initializeAxios2 } from './initializeAxios';
import { guid } from './util';

const isServer = typeof window === 'undefined';

export const _axios = initializeAxios(isServer);
export const _axios2 = initializeAxios2(isServer);

axiosRetry(_axios, { retries: 2 });
axiosRetry(_axios2, { retries: 2 });

_axios.interceptors.request.use(
  config => {
    const cookie = new Cookies();
    if (cookie.get('deviceId')) {
      config.headers.deviceId = cookie.get('deviceId');
    } else {
      const deviceId = guid();
      cookie.set('deviceId', deviceId, { expires: new Date(9999, 1, 1) });
      config.headers.deviceId = deviceId;
    }
    return config;
  },
  error => Promise.reject(error)
);

_axios2.interceptors.request.use(
  config => {
    const cookie = new Cookies();
    if (cookie.get('deviceId')) {
      config.headers.deviceId = cookie.get('deviceId');
    } else {
      const deviceId = guid();
      cookie.set('deviceId', deviceId, { expires: new Date(9999, 1, 1) });
      config.headers.deviceId = deviceId;
    }
    return config;
  },
  error => Promise.reject(error)
);

_axios.interceptors.response.use(
  // response
  response => {
    const { data } = response;
    if (data && data.code) {
      const { code, message } = data;
      if (code === 420) return response;
      if (code === 401) {
        mitt.emit(EVENT.USER_STATUS_401);
        return;
      }
      if (typeof window !== 'undefined') {
        setTimeout(() => Toast.fail(message), 50);
      }
    }
    return response;
  },
  // error
  error => {
    const { response = {} } = error;
    const { status } = response;
    if (status >= 500 || status === 0) {
      Toast.fail('网络异常，请稍后重试');
      return Promise.reject(error);
    } else return Promise.resolve(response);
  }
);

_axios2.interceptors.response.use(
  // response
  response => {
    const { data } = response;
    if (data && data.code) {
      const { code, message } = data;
      if (code === 420) return response;
      if (code === 401) {
        mitt.emit(EVENT.USER_STATUS_401);
        return;
      }
      if (typeof window !== 'undefined') {
        setTimeout(() => Toast.fail(message), 50);
      }
    }
    return response;
  },
  // error
  error => {
    const { response = {} } = error;
    const { status } = response;
    if (status >= 500 || status === 0) {
      Toast.fail('网络异常，请稍后重试');
      return Promise.reject(error);
    } else return Promise.resolve(response);
  }
);
// APIs

export const account = {
  register: credential => _axios.post('/api/register', credential),
  checkMobile: ({ mobile }) =>
    _axios.post('/api/login/checkMobile', { mobile }),
  login: credential => _axios.post('/api/login/union', credential),
  wxLogin: code => _axios.post('/api/login/wx', { code }),
  logout: () => _axios.get('/api/logout'),
  bindMobile: form => _axios.post('/api/my/bindMobile', form),
  bindMobile2: form => _axios2.post('/api/my/bindMobile', form),
  checkMobile2: form => _axios2.post('/api/my/checkMobileWithWechat', form),
  bindNewMobile: form => _axios.post('/api/my/bindNewMobile', form),
  bindMobileForActivity: form =>
    _axios.post('/api/my/bindMobileForActivity', form),
  retrieveSellerInfo: userId => _axios.get(`/api/user/${userId}`),
  postCount: ()=>_axios2.get('/api/my/postCount'),
};
export const SMS = {
  send: form => _axios.post('/api/register/sendSMSCode', form),
  sendVoiceCode: form => _axios.post('/api/register/sendVoiceCode', form),
  verify: ({ mobile, code }) =>
    _axios.post('/api/register/verifySMSCode', { mobile, code }),
  setPassword: ({ mobile, code, password }) =>
    _axios.post('/api/login/setPasswordAndLogin', { mobile, code, password }),
  register: credential => _axios.post('/api/register/mobile', credential),
  fault: form => _axios.post('/api/register/manual', form),
  password: {
    sendVoiceCode: ({ mobile }) =>
      _axios.post('/api/password/sendVoiceCode', { mobile }),
    send: ({ mobile }) => _axios.post('/api/password/sendSMSCode', { mobile }),
    verify: ({ mobile, code }) =>
      _axios.post('/api/password/verifySMSCode', { mobile, code })
  }
};
export const profile = {
  get: () => _axios.get('/api/my/profile'),
  update: form => _axios.post('/api/my/profile', form)
};
export const merchant = {
  create: form => _axios.post('/api/merchant', form),
  update: (id, form) => _axios.patch(`/api/merchant/${id}`, form),
  delete: id => _axios.delete(`/api/merchant/${id}`),
  search: params => _axios.get(`/api/merchant/search`, { params }),
  id: id => _axios.get(`/api/merchant/${id}`),
  list: params =>
    _axios.get('/api/merchant', {
      params
    })
};
export const my = {
  initUserStore: () => _axios.get('/api/my/initUserStore'),
  getProfile: () => _axios.get('/api/my/profile'),
  contact: form => _axios.post('/api/my/contact', form),
  conversations: () => _axios.get('/api/my/conversations'),
  hideConversation: form => _axios.post('/api/my/hideConversation', form),
  contactSeller: form => _axios.post('/api/my/contactSeller', form),
  sendChat: form => _axios.post('/api/my/sendChat', form),
  getConversation: id => _axios.get(`/api/my/conversation/${id}`),
  fetchConversation: id => _axios.get(`/api/my/conversation/fetch/${id}`),
  getMerchant: () => _axios.get('/api/my/merchant'),
  refreshUserLevel: () => _axios.get('/api/my/userLevel'),
  renewTopic: id => _axios.post(`/api/my/renewTopic/${id}`),
  commentMsg: page => _axios.get(`/api/my/message/comment?page=${page}`),
  systemMsg: page => _axios.get(`/api/my/message/system?page=${page}`),
  coupons: () => _axios.get('/api/my/coupon'),
  useCoupon: id => _axios.post('/api/my/coupon', { coupon_id: id }),
  getActivityById: id => _axios.get(`/api/tt/activity/${id}`),
  getCouponById: id => _axios.get(`/api/tt/tt-coupon/${id}`),
  grabCouponById: id => _axios.post(`/api/tt/tt-coupon/${id}/grab`)
};

export const email = {
  verification: {
    request: () => _axios.get('/api/my/verify/email'),
    do: ({ token }) => _axios.post('/api/my/verify/email', { token })
  }
};
export const password = {
  update: ({ old_password, new_password }) =>
    _axios.post('/api/my/password', { old_password, new_password }),
  reset: form => _axios.post('/api/password/reset', form)
};
export const activity = {
  create: form => _axios.post('/event/apply', form),
  create2: form => _axios2.post('/event/apply', form),
  getSignUpUsers: topicId =>
    _axios.get(`/api/activity/getSignUpUsers/${topicId}`),
  sendMsg: form => _axios.post('/api/activity/sendMessage', form),
  getHotAiXueActivity: () => _axios.get('/api/tt/hot-aixue-activity')
};

export const topic = {
  list: (params = { type: 'latest' }) => {
    const { city_id, ...rest } = params;
    return _axios.get('/api/topic', {
      params: { ...rest, city_id: city_id === 0 ? undefined : city_id }
    });
  },
  homeFeed: params => _axios.get('/api/home/feed', { params }),
  socialFeed: params => _axios.get('/api/social/feed', { params }),
  search: params => _axios.get('/api/topic/search', { params }),
  id: id => _axios.get(`/api/topic/${id}`),
  watch: id => _axios.post(`/api/my/watchlist`, { id }),
  deleteWatch: id => _axios.delete(`/api/my/watchlist/${id}`),
  create: form => _axios.post('/api/topic', form),
  update: (id, form) => _axios.patch(`/api/topic/${id}`, form),
  delete: id => _axios.delete(`/api/topic/${id}`),
  report: (id, { content }) =>
    _axios.post(`api/topic/${id}/report`, { content }),
  comment: {
    post: (topicId, params) =>
      _axios.post(`api/comment?commentable_id=${topicId}`, params),
    list: (topicId, commentable_type = 'topic', page) =>
      _axios.get(
        `api/comment?commentable_id=${topicId}&commentable_type=${commentable_type}&page=${page}`
      ),
    delete: commentId => _axios.delete(`api/comment/${commentId}`),
    report: (id, { content }) =>
      _axios.post(`api/comment/${id}/report`, { content })
  },
  getTopicByGroup: params => _axios.get(`/api/topic`, { params })
};

export const car = {
  getModelByPlate: plate => _axios.get(`/api/car/getModelByPlate/${plate}`),
  valuationByModel: car_info =>
    _axios.get('/api/car/valuationByModel', {
      params: car_info
    })
};

export const initConfig = () => _axios.get('/api/config');

export const Ad = {
  popup: params => _axios.get('/api/config/popup', { params }),
  popupClick: id => _axios.post(`/api/popups/${id}/click`)
};

export const AreaLevel3 = id => _axios.get(`/api/config/getSubArea/${id}`);

export const s3 = {
  upload: async function(file, onProgress) {
    const data = new FormData();
    const f = file.slice();
    data.append('file', f);
    return _axios.post(`api/image/upload`, data, {
      onUploadProgress: function(progressEvent) {
        let percent = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress && onProgress({ percent });
      }
    });
  },

  uploadAvatar: async function(file) {
    const data = new FormData();
    const resizedFile = file.slice();
    data.append('file', resizedFile);

    return _axios.post(`api/image/upload`, data);
  }
};
export const validation = {
  email: email =>
    new Promise((resolve, reject) => {
      jsonp(
        `https://api.mailgun.net/v3/address/validate?address=${email}&api_key=pubkey-0b80f8c1f493767475660f51444e9104`,
        null,
        (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        }
      );
    })
};

export const question = {
  list: params => _axios.get('/api/question', { params }),
  create: form => _axios.post('/api/question', form),
  id: id => _axios.get(`/api/question/${id}`)
};

export const wxWeb = {
  getJSSDKConfig: params =>
    _axios2.get('/api/wechat/jsSDKConfig', {
      params: params
    }),
  unifyOrder: params => _axios.post('/api/wx/unifyOrder', params),
  unifyOrderV3: params => _axios2.post('/api/wechat/unifiedOrderV3', params),
  // getJSSDKConfigPay: url => _axios.get('/api/wx/jssdkConfigPay', {
  //   params: {url}
  // }),
};

export const wxMini = {
  unifyOrder: params => _axios.post('/api/wx/unifyOrderMini', params)
};

export const order = {
  id: id => _axios.get(`/api/order/${id}`),
  maskOrders:(params)=> _axios2.get(`/api/order/masks`, {params}),
  orders:(params)=> _axios2.get(`/api/order/list/app`, {params}),
  create: form => _axios.post(`/api/order/create`, form),
  charge: form => _axios.post(`/api/order/charge`, form),
  windcaveCreateSession: params =>
    _axios.post(`/api/order/windcaveCreateSession`, params),
  queryOrderResult: params =>
    _axios.get(`/api/order/queryOrderResult`, { params }),
  paymentMethod:(data)=> _axios2.patch(`/api/order/payment-method`, data),
  cancel: (orderId) => _axios2.patch('/api/order/cancel', { orderId }),
  checkOrder: (orderId) => _axios2.get('/api/order/checkOrderStatus', { params: {orderId: orderId} }),
};

export const redPacket = {
  grab: (topicId, ref) => _axios.post('/api/redPacket/grab', { topicId, ref }),
  detail: topicId => _axios.get(`/api/redPacket/${topicId}`),
  read: ids => _axios.post(`/api/redPacket/read`, { ids: ids })
};

export const admin = {
  simulateLogin: mobile => _axios.post('/api/admin/simulateLogin', { mobile })
};

export const group = {
  recommend: () => _axios.get(`/api/group/recommend`),
  category: cate => _axios.get(`/api/group/category/${cate}`),
  my: () => _axios.get(`/api/group/my`),
  id: id => _axios.get(`/api/group/${id}`),
  users: (id, params = { page: 1 }) =>
    _axios.get(`/api/group/${id}/user`, { params }),
  join: groupId => _axios.post(`api/group/join`, { groupId: groupId }),
  leave: groupId => _axios.post(`api/group/leave`, { groupId: groupId }),
  topic: params => _axios.get(`api/group/my/topic`, { params }),
  removeTopicFromGroup: (groupId, topicId) =>
    _axios.post(`/api/group/removeTopicFromGroup`, { groupId, topicId })
};

export const article = {
  get: id => _axios.get(`/api/article/${id}`),
  list: params => _axios.get('/api/article', { params }),
  aixueHomeArticle: () => _axios.get('/api/aixue/home-article'),
  getAiXueArticle: () => _axios.get('/api/aixue/article')
};

export const event = {
  getInfoByUser: id => _axios.get(`/api/activity/getMySignUp/${id}`),
  sendQuestionnaire: params =>
    _axios.post('/api/activity/sendQuestionnaire', params),
  setSystemInquiry: params =>
    _axios.post('/api/system/merchant/inquiry', params),
  chinz88Recommend: params=> _axios2.post('api/chinz88/register', params)
};

export default {
  account,
  SMS,
  password,
  email,
  profile,
  my,
  topic,
  activity,
  car,
  initConfig,
  s3,
  validation,
  question,
  wxWeb,
  wxMini,
  merchant,
  order,
  redPacket,
  admin,
  group,
  AreaLevel3,
  Ad,
  article,
  event
};
